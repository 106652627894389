<template>
    <div class="backtopBox" :style="{width:width,display:display,width:width+'px',height:height+'px',bottom:bottom+'px',right:right+'px',color:color}"  @click="toTop()">
        <slot>up</slot>
    </div>
</template>
<script>
    export default {
	data () {
		return {
            display:'none',
            scrollTop:0
		}
	},
    props:{
        width:{
            type:Number,
            default:30
        },
        height:{
            type:Number,
            default:30
        },
        bottom:{
            type:Number,
            default:90
        },
        right:{
            type:Number,
            default:50
        },
        color:{
            type:String,
            default:'#1989fa'
        }
    },
    // 滚动监听
	mounted () {
    	window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
    methods: {
		// 获取页面滚动距离
	    handleScroll () {
	        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if(this.scrollTop > 300){
                this.display = 'block'
            }else{
                this.display = 'none'
            }
	    },
        // 滚动重置
    	toTop () {
            let t = this.scrollTop
            let time = setInterval(() => {
                t -= 10
                document.documentElement.scrollTop = t 
                if(t<=0){
                    clearInterval(time)
                }          
            },5)
            this.display = 'none'
    	}
	},
    beforeDestroy() {
        window.removeEventListener('scroll', this.throttledScrollHandler);
    }
}
</script>
<style scoped>
    .backtopBox{
        color: #eee;
        position: fixed;
        z-index: 9999;
        font-size: 30px;
        font-weight: 800;
        cursor: pointer;
    }
</style>