<template>
  <div id="app">
    <!-- 内容 -->
    <router-view/>
    <!-- 底部 -->
    <z-footer/>
    <!-- 返回顶部图标 -->
    <z-backtop :width="width" :height="height" :bottom="bottom" :right="right"><img style="width: 100%;" src="./imgs/icon/toTop.png" alt="返回顶部"></z-backtop>
  </div>
</template>
<script>
import zFooter from "./components/footer.vue"
import zBacktop from "./components/z-backtop.vue"
export default {
  components:{zFooter,zBacktop},
  data(){
    return{
      width:60,
      height:60,
      bottom:90,
      right:50
    }
  },
  created (){
    // 初始化获取浏览器窗口大小
        let screenWidth = document.body.clientWidth;
        // 根据浏览器窗口大小判断是否显示页面内的部分内容
        if(screenWidth<1200){
            this.width = this.height = 30
            this.bottom = this.right = 20
        }else{
            this.width = this.height = 60
        }
  }
}
</script>
<style>
  *{
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none;
  }
  .typeArea{
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}
  #app{
    width: 100%;
    overflow: hidden;
  }
</style>
