import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: ' ',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    children:[]
  },{
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    children:[]
  },{
    path:'/indexInfo',
    name:'IndexInfo',
    component: () => import('@/views/indexInfo.vue')
  },{
    path: '/brand',
    name: 'Brand',
    component: () => import('@/views/Brand/index.vue'),
    children:[]
  },{
    path: '/craft',
    name: 'Craft',
    component: () => import('@/views/Craft/index.vue'),
    children:[]
  },{
    path: "/qualityInfo",
    name: "QualityInfo",
    component: () => import('@/views/Craft/qualityInfo.vue')
  },{
    path: '/history',
    name: 'History',
    component: () => import('@/views/History/index.vue'),
    children:[]
  },{
    path: '/news',
    name: 'News',
    component: () => import('@/views/News/index.vue'),
    children:[{
      path: 'newsList',
      name: 'NewsList',
      component: () => import('@/views/News/newsList.vue'),
    },{
      path: 'newsInfo',
      name: 'NewsInfo',
      component: () => import('@/views/News/newsInfo.vue'),
    }]
  },{
    path: '/us',
    name: 'Us',
    component: () => import('@/views/Us/index.vue'),
    children:[]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
