import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import axios from 'axios'

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'DIdnF87ExnXZ9CtaNBjuX96UzmEuaDhe'
})

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.axios=axios;

router.beforeEach((to,from,next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
