<template>
    <div>
        <div class="top">
            <div class="typeArea">
                <div class="left">
                    <span>云南勐海产业园区建设投资开发集团有限公司</span>
                    <i v-if="!screenState"></i>
                    <div><img src="../imgs/icon/phone.png"><span>联系电话：0691-3057800</span></div>
                    <div><img src="../imgs/icon/mail.png"><span>联系邮箱：mhxgyyqyt@163.com</span></div>
                    <div><img src="../imgs/icon/map.png"><span>联系地址：云南省西双版纳傣族自治州勐海县勐海镇产业园区</span></div>
                </div>
                <div class="right">
                    <!-- <span>扫码关注</span>
                    <div>
                        <div>
                            <img src="../imgs/icon/wechar.png">
                            <span>微信公众号</span>
                        </div>
                        <div>
                            <img src="../imgs/icon/pro.png">
                            <span>小程序</span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="typeArea">
                <span>技术支持：湖北省智慧农业有限公司</span>
                <span>ICP备案：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: white">滇ICP备2022000826号-1</a> <cite>|</cite> 经营许可证编号：滇B2-20220173</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            screenState:false
        }
    },
    created(){
        // 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}
    },
    methods:{

    }
}
</script>
<style scoped>
    .top{
        height: 281px;
	    background-color: #302f35;
    }
    .top > .typeArea{
        align-items: flex-start;
        justify-content: space-between;
    }
    .bottom .typeArea cite{
        color: rgba(255, 255, 255, 0.4);
        font-style: normal;
        margin: 0 5px;
    }
    .top .left > span{
        /* width: 371px; */
        display: inline-block;
        line-height: 78px;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        border-bottom: 1px solid #ffffff69;
        margin: 22px 0 30px 0;
    }
    .top .left > div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .top .left > div img{
        width: 15px;
        height: 15px;
        margin-right: 23px;
    }
    .top .left > div span{
        font-size: 14px;
        letter-spacing: 0px;
        color: #ffffff;
        line-height: 34px;
    }
    .top .right{
        width: 290px;
        font-size: 18px;
        color: #ffffff;
        margin-top: 50px;
        text-align: center;
    }
    .top .right>div{
        width: 100%;
        display: flex;
    }
    .top .right>div>div{
        width: 50%;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top .right div img{
        width: 120px;
        height: 120px;
    }
    .top .right div span{
        width: 100%;
        text-align: center;
        width: 71px;
        height: 14px;
        font-size: 14px;
        margin-top: 14px;
        color: #ffffff;
        opacity: 0.5;
    }
    .bottom{
        height: 50px;
        width: 100%;
        background-color: #5b4e4a;
    }
    .bottom>.typeArea{
        display: flex;
        justify-content: space-between;

        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
    }
    @media only screen and (max-width: 1199px) {
        .top{
            height: 160px;
            width: 100vw;
            overflow: hidden;
            padding: 0 15px 15px;
            box-sizing: border-box;
        }
        .typeArea{
            width: 100%;
            overflow: hidden;
            height: auto;

        }
        .top .left > span{
            width:auto;
            font-size: 12px;
            margin: 8px 0 0;
            border: none;
            line-height: 44px;
        }
        .top .left >i{
            display:block;
            font-size: 12px;
            margin-bottom:14px;
            width: 10em;
            border-top: 1px solid #ffffff69;
        }
        .top .left > div img{
            width: 8px;
            height: 8px;
            margin-right: 8px;
        }
        .top .left > div span{
            font-size: 10px;
            line-height: 20px;
        }
        .top .right{
            width: 135px;
            margin-top: 25px;
            font-size: 12px;
        }
        .top .right>div>div{
            margin-top: 15px;
        }
        .top .right div img{
            width: 60px;
            height: 60px;
        }
        .top .right div span{
            margin-top: 5px;
            font-size: 10px;
        }
        .bottom{
            height: 60px;
            display: flex;
            align-items: center;
        }
        .bottom > .typeArea{
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 20px;
            font-family: 8px;
            line-height: 16px;
        }
    }
</style>